import React from 'react'
import { navigate } from 'gatsby-link'
import styled, { css } from "styled-components"
import { Checkbox } from "../components/checkbox"
import { Button } from "../components/button"

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const Wrapper = styled.div`
  text-align: center;
  color: black;
`

const StyledForm = styled.form`
  width: 70%;
  margin: 15%;
  background-color: white;
  padding: 50px;
  border-radius: 15px;
  @media (max-width: 768px) {
    padding: 25px;
  }
`

const Honeypot = styled.div`
  display: none;
`
const FormGroup = styled.div`
  position: relative;
  border-bottom: 2px solid #adadad;
  margin-bottom: 37px;
`

const StyledInputAndTextArea = css`
  outline: none;
  border: none;
  line-height: 1.2;
  width: 100%;
  font-size: 0.8rem;

  &:focus ~ Span,
  &:not(:placeholder-shown) ~ Span {
    top: -10px;
    bottom: 10px;
    left: 0px;
    font-size: 11px;
    opacity: 1;
  }
`

const Input = styled.input`
  ${StyledInputAndTextArea}
  height: 60px;
`

const TextArea = styled.textarea`
  ${StyledInputAndTextArea}
  min-height: 120px;
  padding-top: 18px;
  resize: vertical; 
  &:focus ~ Span,
  &:not(:placeholder-shown) ~ Span {
    top: -20px;
  }
`

const Span = styled.span`
  position: absolute;
  left: 0px;
  top: 18px;
  transition: 0.2s ease all;
  font-size: 0.8rem;
  text-transform: uppercase;
  color: gray;
  pointer-events: none;
`

const Title = styled.h1`
  padding-top: 50px;
  padding-bottom: 50px;

  @media (max-width: 768px) {
    padding-top: 25px;
    padding-bottom: 25px;
  }
`

export default class Form extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    if(Number(this.state['Numer telefonu'] && this.state['Numer telefonu'].length > 9)){
      navigate(form.getAttribute('action'));
    } else {
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': form.getAttribute('name'),
          ...this.state,
        }),
      })
        .then(() => navigate(form.getAttribute('action')))
        .catch((error) => alert(error))
    }
  }

  render() {
    return (
      <Wrapper>
    <StyledForm
      method="post"
      data-netlify="true"
      netlify-honeypot="bot-field"
      enctype="application/x-www-form-urlencoded"
      name="newContact"
      onSubmit={this.handleSubmit}

    >
      <input type="hidden" name="form-name" value="contact" />
      <Title>Skontaktuj się ze mną</Title>
      <Honeypot>
        <label>
          Don’t fill this out if you are human: <Input name="bot-field" />
        </label>
      </Honeypot>
      <FormGroup>
        <Input type="text" name="Imię" placeholder=" " onChange={this.handleChange}/>
        <Span>imie</Span>
      </FormGroup>
      <FormGroup>
        <Input type="text" name="Numer telefonu" placeholder=" " onChange={this.handleChange} />
        <Span>telefon / email</Span>
      </FormGroup>
      <FormGroup>
        <Input type="text" name="Temat wiadomości" placeholder=" " onChange={this.handleChange}/>
        <Span>temat wiadomości</Span>
      </FormGroup>
      <FormGroup>
        <TextArea name="Treść" placeholder=" " onChange={this.handleChange}/>
        <Span>treść</Span>
      </FormGroup>

      <Checkbox name="RODO" value="Wyrażono zgodę" text="Wysyłając formularz wyrażam zgodę na przetwarzanie danych osobowych w
        celu otrzymania odpowiedzi na tą wiadomość. Administratorem Twoich
        danych jest Rafał Petryka. W każdej chwili masz prawo do usunięcia danych i
        wypisu." required></Checkbox>
      <Button type="submit">Wyślij</Button>
    </StyledForm>
  </Wrapper>
    )
  }
}