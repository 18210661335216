import React from "react"
import { Link, StaticQuery } from "gatsby"
import styled, { css } from "styled-components"
import BackgroundImage from "gatsby-background-image"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const Span = styled.span`
  position: absolute;
  transition: transform 0.425s ease-in-out;
  z-index: -1;
  left: 0;
  top: 0;
  width: 200%;
  height: 100%;
  background: linear-gradient(135deg, #6ba0b1 0%, #d41ec6 100%);
`;

const Wrapper = styled.div`
  position: relative;
  padding-top: 0.7rem;
  padding-left: 0.9375rem;
  padding-right: 0.9375rem;
  width: 240px;
  margin: 0 auto;
`

const StyledButton = styled.button`
  backface-visibility: hidden;
  box-shadow: 0 6px 12px rgba(0,0,0,0.4);
  color: #fff;
  display: block;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.0625rem;
  margin-top: 1.5625rem;
  padding: 1rem;
  position: relative;
  overflow: hidden;
  text-align: center;
  text-transform: uppercase;
  transform-style: preserve-3d;
  will-change: transform;
  z-index: 1;

  &:hover Span {
    transform: translate3d(-50%, 0, 0);
  }
`

export const Button = () => (
  <Wrapper>
    <StyledButton>
      Wyślij
      <Span class="Button-mask Button-slides slides-left Graident-one"></Span></StyledButton>
  </Wrapper>
)
