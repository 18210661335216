import React from "react"
import { Link, StaticQuery } from "gatsby"
import styled, { css } from "styled-components"
import BackgroundImage from "gatsby-background-image"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const Wrapper = styled.div`
  text-align: center;
  color: "#333333";
  font-size: 0.6rem;
  line-height: 0.8rem;
  display: flex;
`

const CheckboxLabel = styled.label`
  display: block;
  position: relative;
  margin: auto;
  cursor: pointer;
  font-size: 22px;
  line-height: 24px;
  height: 24px;
  width: 70px;
  margin-right: 10px;
  clear: both;

  @media (max-width: 768px) {
    width: 115px;
  }

  @media (max-width: 375px) {
    width: 150px;
  }
`

const Text = styled.div`
  text-align: justify;
`

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;

  &:checked ~ Span {
    background-color: transparent;
    border-radius: 5px;
    -webkit-transform: rotate(0deg) scale(1);
    -ms-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
    opacity: 1;
    border: 2px solid #009bff;
  }
  &:checked ~ Span::after {
    -webkit-transform: rotate(45deg) scale(1);
    -ms-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
    opacity: 1;
    left: 8px;
    top: 3px;
    width: 6px;
    height: 12px;
    border: solid #009bff;
    border-width: 0 2px 2px 0;
    background-color: transparent;
    border-radius: 0;
  }

  &:checked ~ Span::before {
    left: -3px;
    top: -3px;
    width: 24px;
    height: 24px;
    border-radius: 5px;
    -webkit-transform: scale(3);
    -ms-transform: scale(3);
    transform: scale(3);
    opacity: 0;
    z-index: 999;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
  }
`

const Span = styled.span`
  position: absolute;
  top: 0px;
  left: 0px;
  height: 24px;
  width: 24px;
  background-color: transparent;
  border-radius: 5px;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -ms-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  border: 2px solid #009bff;

  &::after {
    position: absolute;
    content: "";
    left: 12px;
    top: 12px;
    height: 0px;
    width: 0px;
    border-radius: 5px;
    border: solid #009bff;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(0deg) scale(0);
    -ms-transform: rotate(0deg) scale(0);
    transform: rotate(0deg) scale(0);
    opacity: 1;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
  }

  &::before {
    position: absolute;
    content: "";
    left: 10px;
    top: 10px;
    width: 0px;
    height: 0px;
    border-radius: 5px;
    border: 2px solid #eaeaea;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);
  }
`

export const Checkbox = ({ name, value, required, text }) => (
  <Wrapper>
    <CheckboxLabel>
      <Input
        type="checkbox"
        name={name}
        value={value}
        required={required}
      ></Input>
      <Span></Span>
    </CheckboxLabel>
    <Text>{text}</Text>
  </Wrapper>
)
